<template>
    <v-card light tile class="mb-4">
        <v-card-title>Technical Settings</v-card-title>
        <v-card-text>
            <v-form class="ml-4" v-model="valid">
                <v-row>
                    <v-col :lg="6">
                        <div class="subtitle-1 text-left">
                            Database Technicals
                        </div>
                        <v-text-field
                            :value="technicalSettings.database.cpu"
                            @input="
                                (v) =>
                                    (technicalSettings.database.cpu = v || '')
                            "
                            clearable
                            label="CPU"
                            name="database_cpu"
                            :rules="[rules.cpu_match]"
                        ></v-text-field>
                        <v-text-field
                            :value="technicalSettings.database.memory"
                            @input="
                                (v) =>
                                    (technicalSettings.database.memory =
                                        v || '')
                            "
                            clearable
                            label="Memory"
                            name="database_memory"
                            :rules="[rules.memory_match]"
                        ></v-text-field>
                    </v-col>
                    <v-col :lg="6">
                        <div class="subtitle-1 text-left">
                            Backend Technicals
                        </div>
                        <v-text-field
                            :value="technicalSettings.backend.cpu"
                            @input="
                                (v) => (technicalSettings.backend.cpu = v || '')
                            "
                            clearable
                            label="CPU"
                            name="backend_cpu"
                            :rules="[rules.cpu_match]"
                        ></v-text-field>
                        <v-text-field
                            :value="technicalSettings.backend.memory"
                            @input="
                                (v) =>
                                    (technicalSettings.backend.memory = v || '')
                            "
                            clearable
                            label="Memory"
                            name="backend_memory"
                            :rules="[rules.memory_match]"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
	name: 'TechnicalSettingsForm',

	data() {
		return {
			valid: true,
			rules: {
				required: (v) => !!v || 'Required.',
				require_one: (v) =>
					v.length >= 1 || 'Must Enable At Least One.',
				memory_match: (v) =>
					/^\d*.?\d*(M|Mi|G|Gi)$/.test(v) ||
					v == '' ||
					'Must be a valid Memory value. Ex. 1G, 1Gi, 750M, 750Mi',
				cpu_match: (v) =>
					/^\d*.?\d*$/.test(v) ||
					v == '' ||
					'Must be a valid CPU value. Ex. 1, 1.5, .5'
			}
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		technicalSettings: {
			get() {
				return this.value
			},
			set(technicalSettings) {
				this.$emit('input', technicalSettings)
			}
		}
	},
	watch: {},
	methods: {
	}
}
</script>

import Api from '@/services/api'

export default {
	fetchApiKeys() {
		return Api().get('apikeys')
	},
	addApiKey(params) {
		return Api().post('apikeys', params)
	},
	updateApiKey(params) {
		return Api().put('apikeys/' + params.id, params)
	},
	getApiKey(params) {
		return Api().get('apikeys/' + params.id)
	},
	deleteApiKey(id) {
		return Api().delete('apikeys/' + id)
	}

}

const dashboardsList = [
	{
		"value": "1",
		"text": "Arca Hub"
	},
	{
		"value": "2",
		"text": "System Setting"
	},
	{
		"value": "3",
		"text": "File Safe"
	},
	{
		"value": "4",
		"text": "Commissions"
	},
	{
		"value": "5",
		"text": "Sales"
	},
	{
		"value": "6",
		"text": "Contracting"
	},
	{
		"value": "7",
		"text": "Office Staff Dashboard"
	},
	// {
	// 	"value": "8",
	// 	"text": "Call Center Dashboard"
	// },
	{
		"value": "10",
		"text": "Portal Dashboard"
	},
	{
		"value": "11",
		"text": "Leads Dashboard"
	},
	{
		"value": "12",
		"text": "Leads"
	},
	{
		"value": "13",
		"text": "Calendar"
	},
	{
		"value": "14",
		"text": "Licensing"
	},
	{
		"value": "15",
		"text": "Customers"
	},
	{
		"value": "16",
		"text": "Financials"
	}
]

export {
	dashboardsList
}

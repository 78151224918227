const portalComponentsList = [
	{
		text: 'My BoB',
		value: 'MyBoB'
	},
	{
		text: 'My Commission Statements',
		value: 'MyCommissions'
	},
	{
		text: 'My AEP Sales',
		value: 'MyAEPSales'
	},
	{
		text: 'Quoting Tool',
		value: 'QuotingTool'
	},
	{
		text: 'Licensing & Contracting',
		value: 'Licensing-Contracting'
	},
	{
		text: 'Support',
		value: 'Support'
	}
]

const routesList = [
	{
		value: '/',
		text: 'Default'
	},
	{
		value: '/licensing-contracting',
		text: 'Licensing & Contracting'
	},
	{
		value: '/myBoB',
		text: 'MyBoB'
	},
	{
		value: '/myCommissions',
		text: 'MyCommissions'
	},
	{
		value: '/MyAEPSales',
		text: 'MyAEPSales'
	},
	{
		value: '/Support',
		text: 'Support'
	},
	{
		value: '/QuotingTool',
		text: 'QuotingTool'
	}
]

export {
	portalComponentsList,
	routesList
}
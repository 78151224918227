
<template>
    <v-container fill-height fluid>
        <v-row class="justify-center align-center" height="300px">
            <v-col :lg="6" lg-cols="6">
                <v-card>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Add Post</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                            <v-alert
                                color="error"
                                dark
                                elevation="2"
                                type="error"
                                :value="!!error_message.length"
                                transition="fade-transition"
                                dismissible
                            >{{ error_message }}</v-alert>
                            <v-text-field
                                v-model="title"
                                label="Title"
                                name="title"
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-textarea
                                placeholder="Description"
                                name="Description"
                                label="Description"
                                v-model="description"
                            ></v-textarea>
                            <v-btn
                                v-if="newPost"
                                :disabled="!valid"
                                color="success"
                                @click="addPost"
                            >Add</v-btn>
                            <v-btn
                                v-else
                                :disabled="!valid"
                                color="success"
                                @click="updatePost"
                            >Update</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PostsService from '@/services/posts'
export default {
	name: 'NewPost',
	props: ['newPost'],
	data() {
		return {
			title: '',
			description: '',
			error_message: '',
			valid: true,
			rules: {
				required: v => !!v || 'Required.'
			}
		}
	},
	beforeMount() {
		if (!this.newPost) {
			this.getPost()
		}
	},
	methods: {
		async getPost() {
			const response = await PostsService.getPost({
				id: this.$route.params.id
			})
			this.title = response.data.title
			this.description = response.data.description
		},
		async addPost() {
			await PostsService.addPost({
				title: this.title,
				description: this.description
			})
			this.$router.push({ name: 'Posts' })
		},
		async updatePost() {
			try {
				await PostsService.updatePost({
					id: this.$route.params.id,
					title: this.title,
					description: this.description
				})
				this.$router.push({ name: 'Posts' })
			} catch (e) {
				console.error(e.response.data)
				this.error_message = e.response.data.message
					? e.response.data.message
					: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
		}
	}
}
</script>
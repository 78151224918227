import Vue from 'vue'
import Router from 'vue-router'
import { Login } from '@/pages/login'
import App from '@/pages/main/App'
import store from '@/store/index.js'
import { Posts, NewPost } from '../pages/posts'
import { ApiKeys, NewApiKey } from '../pages/apikeys'
import { Clients, NewClient } from '../pages/clients'
import { Users, NewUser, EditUser } from '../pages/users'

Vue.use(Router)

const router = new Router({
	mode: "history",
	routes: [
		{
			path: '/',
			component: App,
			redirect: '/clients',
			meta: {
				requiresAuth: true
			},
			children: [
				{
					path: '/posts',
					name: 'Posts',
					component: Posts
				},
				{
					path: '/posts/new',
					name: 'NewPost',
					component: NewPost,
					props: { newPost: true }
				},
				{
					path: '/posts/:id',
					name: 'EditPost',
					component: NewPost,
					props: { newPost: false }
				},
				{
					path: '/clients',
					name: 'Clients',
					component: Clients
				},
				{
					path: '/clients/new',
					name: 'NewClient',
					component: NewClient,
					props: { newClient: true }
				},
				{
					path: '/clients/:id',
					name: 'EditClient',
					component: NewClient,
					props: { newClient: false }
				},
				{
					path: '/apikeys',
					name: 'ApiKeys',
					component: ApiKeys
				},
				{
					path: '/apikeys/new',
					name: 'NewApiKey',
					component: NewApiKey,
					props: { newKey: true }
				},
				{
					path: '/apikeys/:id',
					name: 'EditApiKey',
					component: NewApiKey,
					props: { newKey: false }
				},
				{
					path: '/users',
					name: 'Users',
					component: Users
				},
				{
					path: '/users/new',
					name: 'NewUser',
					component: NewUser
				},
				{
					path: '/users/:id',
					name: 'EditUser',
					component: EditUser
				}
			]
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
	]
})
router.beforeEach((to, from, next) => {
	console.log({to})
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters.isLoggedIn) {
			return next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			})
		}
		return next();
	}
	next();
})

export default router;

<template>
    <v-card light tile class="mb-4">
        <v-card-title>Primary Contact</v-card-title>
        <v-card-text>
            <v-form class="ml-4" v-model="valid">
                <v-text-field
                    v-model="primaryContact.name"
                    label="Name"
                    name="name"
                    class="required"
                    :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                    v-model="primaryContact.email"
                    label="Email"
                    name="email"
                    class="required"
                    :rules="[rules.required, rules.valid_email]"
                ></v-text-field>
                <v-text-field
                    v-model="primaryContact.phone"
                    label="Phone"
                    name="phone"
                    class="required"
                    v-mask="'###-###-####'"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import { mask } from 'vue-the-mask'

export default {
	name: 'PrimaryContactForm',
	directives: {
		mask
	},

	data() {
		return {
			valid: true,
			rules: {
				required: v => !!v || 'Required.',
				valid_email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
			}
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		primaryContact: {
			get() {
				return this.value
			},
			set(primaryContact) {
				this.$emit('input', primaryContact)
			}
		}
	}
}
</script>
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'

import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/'

Vue.use(Vuex)
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
	router,
	vuetify,
	store,
	render: (createElement) => createElement(App)
}).$mount('#app')

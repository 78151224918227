<template>
    <v-app id="app" dark>
        <router-view></router-view>
    </v-app>
</template>

<script>
export default {
	name: 'App'
}
</script>

<template>
    <v-container fill-height fluid>
        <v-row class="justify-center fill-height">
            <v-col>
                <v-alert
                    color="error"
                    dark
                    elevation="2"
                    type="error"
                    :value="!!error_message.length"
                    transition="fade-transition"
                    dismissible
                    >{{ error_message }}</v-alert
                >
                <v-alert
                    color="success"
                    dark
                    elevation="2"
                    type="success"
                    :value="!!success_message.length"
                    transition="fade-transition"
                    dismissible
                    >{{ success_message }}</v-alert
                >
                <v-card light class="fill-height" scrollable>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Clients</v-toolbar-title>
                        <v-spacer />
                        <v-btn
                            class="ma-2"
                            tile
                            @click="openMigrationModal"
                            color="green"
                        >
                            <v-icon left>mdi-rocket</v-icon>Migrate DB
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            outlined
                            tile
                            @click="deployClient('front')"
                            color="orange"
                        >
                            <v-icon left>mdi-rocket</v-icon>Deploy TBOP Front
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            outlined
                            tile
                            @click="deployClient('portal')"
                            color="orange"
                        >
                            <v-icon left>mdi-rocket</v-icon>Deploy Portal Front
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            outlined
                            tile
                            @click="deployAllClients()"
                            color="orange"
                        >
                            <v-icon left>mdi-rocket</v-icon>Deploy All Back-End
                        </v-btn>
                        <v-btn
                            :disabled="!clients.length"
                            large
                            color="primary"
                            :to="{ name: 'NewClient' }"
                            >Create New Client</v-btn
                        >
                    </v-toolbar>
                    <v-card-text>
                      <template v-if="loading">
                            <div class="mb-4">
                                Please wait... Loading Clients
                            </div>
                        </template>
                        <template v-else-if="clients.length > 0" class="table-wrap">
                            <v-simple-table :dense="false" :fixed-header="true">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="5%">
                                                Active
                                            </th>
                                            <th class="text-left" width="10%">
                                                Name
                                            </th>
                                            <th class="text-left" width="15%">
                                                Company Name
                                            </th>
                                            <th class="text-left" width="20%">
                                                Primary Contact
                                            </th>
                                            <th class="text-center" width="50%">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="client in clients"
                                            :key="client.id"
                                            :class="{ 'highlight-row': client.name === 'dev' || client.name === 'stage' }"
                                        >
                                            <td class="text-center">
                                                <v-avatar
                                                    v-if="client.active"
                                                    color="green"
                                                    size="12"
                                                ></v-avatar>
                                                <v-avatar
                                                    v-else
                                                    color="red"
                                                    size="12"
                                                ></v-avatar>
                                            </td>
                                            <td class="text-left">
                                                {{ client.name }}
                                            </td>
                                            <td class="text-left">
                                                {{ client.company_name }}
                                            </td>
                                            <td v-if="client.primary_contact" class="text-left">
                                                <div>
                                                    <span
                                                        class="subtitle-2 mr-1"
                                                        >Name:</span
                                                    >
                                                    {{
                                                        client.primary_contact.name
                                                    }}
                                                </div>
                                                <div>
                                                    <span
                                                        class="subtitle-2 mr-1"
                                                        >Email:</span
                                                    >
                                                    {{
                                                        client.primary_contact.email
                                                    }}
                                                </div>
                                                <div>
                                                    <span
                                                        class="subtitle-2 mr-1"
                                                        >Phone:</span
                                                    >
                                                    {{
                                                        client.primary_contact.phone
                                                    }}
                                                </div>
                                            </td>
                                            <td v-else></td>
                                            <td class="text-right">
                                                <v-btn
                                                    v-if="client.active && client.primary_contact"
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                    @click="
                                                        deployClient(client)
                                                    "
                                                    color="orange"
                                                >
                                                    <v-icon left
                                                        >mdi-rocket</v-icon
                                                    >Deploy {{client.name}} Back-End
                                                </v-btn>
                                                <v-btn
                                                    v-if="client.primary_contact"
                                                    :to="{
                                                        name: 'EditClient',
                                                        params: {
                                                            id: client.id
                                                        }
                                                    }"
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                >
                                                    <v-icon left
                                                        >mdi-pencil</v-icon
                                                    >Edit
                                                </v-btn>
                                                <v-btn
                                                    v-if="client.primary_contact"
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                    @click="
                                                        deleteClient(client.id)
                                                    "
                                                    color="error"
                                                >
                                                    <v-icon left
                                                        >mdi-delete</v-icon
                                                    >Delete
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                        <template v-else>
                            <div class="mb-4">
                                There are no clients.. Lets add one now
                            </div>
                            <div>
                                <v-btn
                                    large
                                    color="primary"
                                    :to="{ name: 'NewClient' }"
                                    >Add Client</v-btn
                                >
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <modal-dialog ref="modalDialog"></modal-dialog>
        <yes-no-dialog ref="yesNoDialog"></yes-no-dialog>
    </v-container>
</template>

<script>
import ClientsService from '@/services/clients'
import Dialog from '@/components/choiceModal'
import YesNoDialog from '@/components/yesNoModal'

export default {
	name: 'clients',
	data() {
		return {
			loading: true,
			error_message: '',
			success_message: '',
			clients: [],
			dumps: {}
		}
	},
	components: {
		'modal-dialog': Dialog,
		'yes-no-dialog': YesNoDialog
	},
	beforeMount() {
		this.getClients()
	},
	methods: {
		successHandler(msg) {
			this.success_message = msg
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.success_message = ''
			}, 4000)
		},

		errorHandler(e) {
			console.error(e)
			this.error_message = e.response.data.message
				? e.response.data.message
				: 'An Error Occurred'
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.error_message = ''
			}, 4000)
		},
		async getClients() {
			try {
				this.loading = true
				const getClientsRes = await ClientsService.fetchClients()
				this.clients = [...getClientsRes.data]
			} catch (e) {
				this.errorHandler(e)
			} finally {
				this.loading = false
			}
		},
		async deleteClient(id) {
			try {
				await ClientsService.deleteClient(id)
				this.clients = this.clients.filter(x => x.id !== id)
				this.successHandler('Successfully Deleted Client')
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async migrateDB(fromClient, toClient) {
			try {
				const from = typeof fromClient === 'string' ? fromClient : fromClient.name
				const to = typeof toClient === 'string' ? toClient : toClient.id
				await ClientsService.migrateDB(from, to)
				this.successHandler(
					'Successfully Triggered Migration of DB from : ' + from +' to ' + to
				)
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async deployClient(client) {
			try {

				const dialogRes = await this.$refs.yesNoDialog.pop({
					title: 'Deploy Client',
					message: `Are you sure you want to deploy "${typeof client === 'string' ? client : client.name}"?`
				})

				if (dialogRes) {
					const clientName = typeof client === 'string' ? client : client.name
					const clientId = typeof client === 'string' ? client : client.id
					await ClientsService.deployClient(clientId)
					this.successHandler(
						'Successfully Triggered Deployment Pipeline For Client: ' +
            clientName
					)
				}
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async deployAllClients() {
			try {
				const dialogRes = await this.$refs.yesNoDialog.pop({
					title: 'Deploy all clients',
					message: `Are you sure you want to deploy all clients?`
				})

				if (dialogRes) {
					await ClientsService.deployAllClients()
					this.successHandler(
						'Successfully Triggered Deployment Pipeline For All Clients'
					)
				}
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async openMigrationModal() {
			const { from, to } = await this.$refs.modalDialog.pop({
				title: 'Migrate DB',
				message: `What migration do you want to start?<br/>Select the client you want to migrate from and the client you want to migrate to.`,
				itemsFrom: this.clients.filter(x => x.active).map(x => x.name),
				itemsTo: this.clients.filter(x => x.active).map(x => x.name).filter(x => x == 'dev'|| x == 'stage'|| x == 'demo'|| x == 'training')
			})
			if(from && to && from !== to) {

				const dialogRes = await this.$refs.yesNoDialog.pop({
					title: 'Migrate DB Confirmation',
					message: `Are you sure you want to start migration from "${from}" to "${to}"?`
				})

				if (dialogRes) {
					await	this.migrateDB(from, to)
				}
			}
		}
	}
}
</script>
<style scoped>
	.highlight-row {
		background-color: #f4fffe;
	}
</style>

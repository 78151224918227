<template>
    <v-app>
        <v-navigation-drawer app clipped dark>
            <v-list>
                <v-list-item
                    v-for="(item, index) in sideBarLinks"
                    :key="index"
                    permanent
                    router-link
                    :to="item.path"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn @click="logout()">
                        <v-icon>mdi-exit-run</v-icon>&nbsp; Log Out
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar color="primary" dark app clipped-left>
            <v-toolbar-title>Manage Clients</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn router-link :to="{name: 'EditUser'}">
                <v-icon>mdi-account</v-icon>
                &nbsp; {{user.email}}
            </v-btn>
        </v-app-bar>
        <v-content dark>
            <v-container fluid fill-height>
                <v-fade-transition mode="out-in">
                    <router-view :key="$route.fullPath"></router-view>
                </v-fade-transition>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import AuthService from '@/services/auth'
export default {
	name: 'App',
	data: () => ({
		sideBarLinks: [
			{
				icon: 'account-box-multiple',
				path: { name: 'Clients' },
				title: 'Clients'
			},
			{
				icon: 'key-outline',
				path: { name: 'ApiKeys' },
				title: 'ApiKeys'
			},
			{
				icon: 'account-multiple',
				path: { name: 'Users' },
				title: 'Users'
			}
		]
	}),
	computed: {
		user() {
			return this.$store.getters.user
		}
	},
	methods: {
		logout() {
			AuthService.logout()
			return this.$router.push({ name: 'Login' })
		}
	}
}
</script>
<style>
.required label::after {
	content: '*';
	margin-left: 0.1rem;
	color: var(--v-accent-base) !important;
}
</style>
<template>
    <v-container fill-height fluid>
        <v-row class="justify-center align-center fill-height" >
            <v-col class="fill-height">
                <v-card light>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                            <v-alert
                                color="error"
                                dark
                                elevation="2"
                                type="error"
                                :value="!!error_message.length"
                                transition="fade-transition"
                                dismissible
                            >{{ error_message }}</v-alert>
                            <v-text-field
                                v-model="email"
                                :rules="[rules.required, rules.valid_email]"
                                label="E-mail"
                                name="email"
                                class="required"
                            ></v-text-field>
                            <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="mr-4"
                                @click="addUser"
                            >Add</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UsersService from '@/services/users'
export default {
	name: 'NewUser',
	data: () => ({
		error_message: '',
		valid: true,
		email: '',
		rules: {
			required: v => !!v || 'Required.',
			valid_email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
		}
	}),
	methods: {
		async addUser() {
			try {
				await UsersService.addUser({
					email: this.email
				})
				this.$router.push({ name: 'Users' })
			} catch (e) {
				console.error(e.response.data)
				this.error_message = e.response.data.message
					? e.response.data.message
					: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
		}
	}
}
</script>
<template>
    <v-container fill-height fluid>
        <v-row class="justify-center align-center" height="300px">
            <v-col :lg="6" lg-cols="6">
                <v-card light>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                            <v-alert
                                color="error"
                                dark
                                elevation="2"
                                type="error"
                                :value="!!error_message.length"
                                transition="fade-transition"
                                dismissible
                            >{{ error_message }}</v-alert>
                            <v-text-field
                                v-model="email"
                                :rules="[rules.required, rules.valid_email]"
                                label="E-mail"
                                name="email"
                                required
                                autofocus
                            ></v-text-field>
                            <v-text-field
                                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required]"
                                :type="show_password ? 'text' : 'password'"
                                name="password"
                                label="Password"
                                v-model="password"
                                class="input-group--focused"
                                @click:append="show_password = !show_password"
                                required
                            ></v-text-field>
                            <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="mr-4"
                                @click="handleSubmit"
                            >Enter</v-btn>
                        </v-form>
                        <!-- <v-snackbar :value="error_message.length" :top="true" color="error">{{ error_message }}</v-snackbar> -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AuthService from '@/services/auth'
export default {
	name: 'Login',
	data: () => ({
		error_message: '',
		valid: true,
		show_password: false,
		password: '',
		email: '',
		rules: {
			required: v => !!v || 'Required.',
			valid_email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
		}
	}),
	mounted() {},
	methods: {
		async handleSubmit(e) {
			e.preventDefault()
			if (this.password.length > 0) {
				try {
					await AuthService.login({
						email: this.email,
						password: this.password
					})
					this.$router.push({
						path: '/'
					})
				} catch (e) {
					console.error(e.response.data)
					this.error_message = e.response.data.message
						? e.response.data.message
						: 'An Error Occurred'
					setTimeout(() => {
						this.error_message = ''
					}, 4000)
				}
			}
		}
	}
}
</script>
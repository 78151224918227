<template>
    <v-container>
        <v-row class="justify-center">
            <v-col :lg="6" lg-cols="6">
                <v-card>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Posts</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <template v-if="posts.length > 0" class="table-wrap">
                            <v-simple-table :dense="false" :fixed-header="true" :height="300">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" width="30%">Title</th>
                                            <th class="text-left" width="30%">Description</th>
                                            <th class="text-center" width="40%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="post in posts" :key="post.id">
                                            <td class="text-left pl-6">{{ post.title }}</td>
                                            <td class="text-left pl-6">{{ post.description }}</td>
                                            <td class="text-right">
                                                <v-btn
                                                    :to="{name:'EditPost', params: { id: post.id }}"
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                >
                                                    <v-icon left>mdi-pencil</v-icon>Edit
                                                </v-btn>
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                    @click="deletePost(post.id)"
                                                    color="error"
                                                >
                                                    <v-icon left>mdi-delete</v-icon>Delete
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                        <template v-else>
                            <div class="mb-4">There are no posts.. Lets add one now</div>
                            <div>
                                <v-btn large color="info" :to="{ name: 'NewPost' }">Add Post</v-btn>
                            </div>
                        </template>
                    </v-card-text>
                    <v-btn
                        v-if="posts.length"
                        absolute
                        color="info"
                        fab
                        bottom
                        right
                        :to="{ name: 'NewPost' }"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PostsService from '@/services/posts'
export default {
	name: 'posts',
	data() {
		return {
			posts: []
		}
	},
	beforeMount() {
		this.getPosts()
	},
	methods: {
		async getPosts() {
			const getPostsRes = await PostsService.fetchPosts()
			this.posts = getPostsRes.data
		},
		async deletePost(id) {
			await PostsService.deletePost(id)
			this.posts = this.posts.filter(x => x.id !== id)
		}
	}
}
</script>
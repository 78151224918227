<template>
    <v-container fill-height fluid>
        <v-row class="justify-center align-center">
            <v-col :lg="12">
                <v-card light>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>{{
                            this.newClient ? 'Add Client' : 'Edit Client'
                        }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!this.newClient && this.clientForm.active"
                            class="ma-2"
                            outlined
                            tile
                            :disabled="!all_valid"
                            @click="deployClient()"
                            color="orange"
                        >
                            <v-icon left>mdi-rocket</v-icon>Update/Deploy
                        </v-btn>
                        <v-btn
                            v-if="!this.newClient"
                            class="ma-2"
                            outlined
                            tile
                            @click="deleteClient()"
                            color="error"
                        >
                            <v-icon left>mdi-delete</v-icon>Delete
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                            <v-alert
                                color="error"
                                dark
                                elevation="2"
                                type="error"
                                :value="!!error_message.length"
                                transition="fade-transition"
                                dismissible
                                >{{ error_message }}</v-alert
                            >
                            <v-alert
                                color="success"
                                dark
                                elevation="2"
                                type="success"
                                :value="!!success_message.length"
                                transition="fade-transition"
                                dismissible
                                >{{ success_message }}</v-alert
                            >
                            <v-row>
                                <v-col :lg="4">
                                    <v-card light tile class="mb-4">
                                        <v-card-title>Client Info</v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="clientForm.name"
                                                label="Abbreviation"
                                                name="name"
                                                class="required"
                                                :disabled="!newClient"
                                                :rules="[rules.required]"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="
                                                    clientForm.company_name
                                                "
                                                label="Company Name"
                                                name="company_name"
                                                class="required"
                                                :rules="[rules.required]"
                                            ></v-text-field>
                                            <v-switch
                                                v-model="clientForm.active"
                                                label="Active"
                                                name="active"
                                            ></v-switch>
                                        </v-card-text>
                                    </v-card>
                                    <primary-contact-form
                                        ref="primary_contact_form"
                                        v-model="clientForm.primary_contact"
                                    ></primary-contact-form>
                                </v-col>
                                <v-col :lg="4">
                                    <client-settings-form
                                        ref="client_settings_form"
                                        v-model="clientForm.client_settings"
                                    ></client-settings-form>
                                    <portal-settings-form
                                        ref="portal_settings_form"
                                        v-model="clientForm.portal_settings"
                                    ></portal-settings-form>
                                </v-col>
                                <v-col :lg="4">
                                    <onboarding-settings-form
                                        ref="onboarding_settings_form"
                                        v-model="clientForm.onboarding_settings"
                                    ></onboarding-settings-form>
                                    <technical-settings-form
                                        ref="technical_settings_form"
                                        v-model="clientForm.technicals"
                                    ></technical-settings-form>

                                    <v-card light tile class="mb-4">
                                        <v-card-title
                                            >Client Images</v-card-title
                                        >
                                        <v-card-text>
                                            <template
                                                v-if="
                                                    !clientForm.logo_small.url
                                                "
                                            >
                                                <v-file-input
                                                    show-size
                                                    label="Logo - Small"
                                                    name="logo_small"
                                                    prepend-icon="mdi-camera"
                                                    :rules="[
                                                        rules.required,
                                                        rules.fileType
                                                    ]"
                                                    class="required"
                                                    @change="
                                                        handleFileUpload(
                                                            $event,
                                                            'logo_small_image'
                                                        )
                                                    "
                                                ></v-file-input>
                                            </template>
                                            <template v-else>
                                                <div class="mb-4">
                                                    <div
                                                        class="subtitle-1 text-left"
                                                    >
                                                        Logo - Small
                                                    </div>
                                                    <v-img
                                                        contain
                                                        aspect-ratio="1"
                                                        width="50px"
                                                        class="mb-4"
                                                        alt="Logo - Small"
                                                        :src="
                                                            clientForm
                                                                .logo_small.url
                                                        "
                                                    >
                                                        <template
                                                            v-slot:placeholder
                                                        >
                                                            <v-row
                                                                class="fill-height ma-0 grey lighten-1"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-icon x-large
                                                                    >mdi-image-broken</v-icon
                                                                >
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                    <div class="text-right">
                                                        <v-btn
                                                            color="error"
                                                            @click="
                                                                clearImage(
                                                                    clientForm.logo_small
                                                                )
                                                            "
                                                            >Clear</v-btn
                                                        >
                                                    </div>
                                                </div>
                                            </template>
                                            <template
                                                v-if="
                                                    !clientForm.logo_large.url
                                                "
                                            >
                                                <v-file-input
                                                    show-size
                                                    label="Logo - Large"
                                                    name="logo_large"
                                                    prepend-icon="mdi-camera"
                                                    :rules="[
                                                        rules.required,
                                                        rules.fileType
                                                    ]"
                                                    class="required"
                                                    @change="
                                                        handleFileUpload(
                                                            $event,
                                                            'logo_large_image'
                                                        )
                                                    "
                                                ></v-file-input>
                                            </template>
                                            <template v-else>
                                                <div class="mb-4">
                                                    <div
                                                        class="subtitle-1 text-left"
                                                    >
                                                        Logo - Large
                                                    </div>
                                                    <v-img
                                                        contain
                                                        aspect-ratio="1"
                                                        alt="Logo - Large"
                                                        class="mb-4"
                                                        :src="
                                                            clientForm
                                                                .logo_large.url
                                                        "
                                                    >
                                                        <template
                                                            v-slot:placeholder
                                                        >
                                                            <v-row
                                                                class="fill-height ma-0 grey lighten-1"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-icon x-large
                                                                    >mdi-image-broken</v-icon
                                                                >
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                    <div class="text-right">
                                                        <v-btn
                                                            class="mr-0"
                                                            color="error"
                                                            @click="
                                                                clearImage(
                                                                    clientForm.logo_large
                                                                )
                                                            "
                                                            >Clear</v-btn
                                                        >
                                                    </div>
                                                </div>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <div class="d-flex">
                                <v-btn
                                    v-if="newClient"
                                    :disabled="submit_disabled"
                                    color="primary"
                                    class="mx-auto"
                                    @click="addClient"
                                    >Add</v-btn
                                >

                                <v-btn
                                    v-else
                                    :disabled="submit_disabled"
                                    color="primary"
                                    class="mx-auto"
                                    @click="updateClient"
                                    >Update</v-btn
                                >
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <modal-dialog ref="modalDialog"></modal-dialog>
    </v-container>
</template>
<script>
import ClientsService from '@/services/clients'
import PrimaryContactForm from './components/primary-contact-form'
import ClientSettingsForm from './components/client-settings-form'
import OnboardingSettingsForm from './components/onboarding-settings-form'
import PortalSettingsForm from './components/portal-settings-form'
import { portalComponentsList } from './components/portal-components-list'
import TechnicalSettingsForm from './components/technical-settings-form'
import Dialog from '@/components/yesNoModal'
export default {
	name: 'NewClient',
	components: {
		'primary-contact-form': PrimaryContactForm,
		'client-settings-form': ClientSettingsForm,
		'portal-settings-form': PortalSettingsForm,
		'onboarding-settings-form': OnboardingSettingsForm,
		'technical-settings-form': TechnicalSettingsForm,
		'modal-dialog': Dialog
	},
	props: ['newClient'],
	watch: {
		clientForm: {
			deep: true,
			handler: function () {
				this.form_change = true
				setTimeout(() => {
					this.primaryContactFormValid = this.$refs.primary_contact_form.valid
					this.clientSettingsFormValid = this.$refs.client_settings_form.valid
					this.portalSettingsFormValid = this.$refs.portal_settings_form.valid
					this.onboardingSettingsFormValid = this.$refs.onboarding_settings_form.valid
					this.technicalSettingsFormValid = this.$refs.technical_settings_form.valid
				}, 0)
			}
		}
	},
	data() {
		return {
			logo_large_image: '',
			logo_small_image: '',
			form_change: false,
			clientForm: {
				name: '',
				company_name: '',
				active: true,
				client_settings: {
					user_threshold: 0,
					user_count: 0,
					allow_app_create: true,
					twoFA_enabled: false
				},
				primary_contact: {
					name: '',
					email: '',
					phone: ''
				},
				onboarding_settings: {
					completed: false
				},
				portal_settings: {
					active: false,
					portal_threshold: 0,
					portal_count: 0,
					defaultSalesRank: 'Quarterly',
					allowedComponentsList: portalComponentsList.map((x) => x.value),
					homePage: '/',
					historicalDate: ''
				},
				logo_large: {
					name: '',
					url: ''
				},
				logo_small: {
					name: '',
					url: ''
				},
				technicals: {
					database: {
						cpu: '',
						memory: ''
					},
					backend: {
						cpu: '',
						memory: ''
					}
				}
			},
			error_message: '',
			success_message: '',
			valid: true,
			primaryContactFormValid: true,
			clientSettingsFormValid: true,
			portalSettingsFormValid: true,
			onboardingSettingsFormValid: true,
			technicalSettingsFormValid: true,
			rules: {
				required: (v) => !!v || 'Required.',
				fileSize: (v) => v.size < 5 * 1024 * 1024 || 'Image size is too large.',
				fileType: (v) =>
					!!(v ? v.type : '').match(/image/) || 'File must be an Image.'
			}
		}
	},
	computed: {
		all_valid: function () {
			return (
				this.valid ||
				this.primaryContactFormValid ||
				this.clientSettingsFormValid ||
				this.portalSettingsFormValid ||
				this.onboardingSettingsFormValid ||
				this.technicalSettingsFormValid
			)
		},
		submit_disabled: function () {
			return (
				!this.valid ||
				!this.primaryContactFormValid ||
				!this.form_change ||
				!this.clientSettingsFormValid ||
				!this.portalSettingsFormValid ||
				!this.onboardingSettingsFormValid ||
				!this.technicalSettingsFormValid
			)
		}
	},
	async beforeMount() {
		if (!this.newClient) {
			await this.getClient()
			this.form_change = false
		}
	},
	methods: {
		handleFileUpload(event, fileMap) {
			this.form_change = true
			this[fileMap] = event
		},

		clearImage(image) {
			image.name = ''
			image.url = ''
			this.form_change = true
			this.updateClient()
		},
		successHandler(msg) {
			this.success_message = msg
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.success_message = ''
			}, 4000)
		},

		errorHandler(e) {
			console.error(e)
			this.error_message = e.response.data.message
				? e.response.data.message
				: 'An Error Occurred'
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.error_message = ''
			}, 4000)
		},
		fillForm(resData) {
			console.log(resData)
			Object.keys(this.clientForm).forEach((key) => {
				if (resData.hasOwnProperty(key)) {
					this.clientForm[key] = resData[key]
				}
			})
		},
		createFormData() {
			const formData = new FormData()
			if (this.logo_small_image) {
				formData.append('logo_small_image', this.logo_small_image)
			}
			if (this.logo_large_image) {
				formData.append('logo_large_image', this.logo_large_image)
			}
			Object.keys(this.clientForm).forEach((key) => {
				if (
					this.clientForm.hasOwnProperty(key) &&
					this.clientForm[key] !== ''
				) {
					formData.append(
						key,
						typeof this.clientForm[key] === 'object'
							? JSON.stringify(this.clientForm[key])
							: this.clientForm[key]
					)
				}
			})
			return formData
		},
		async getClient() {
			try {
				console.log(this.$route.params.id)
				const { id } = this.$route.params
				const response = await ClientsService.getClient({
					id
				})
				this.fillForm(response.data)
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async addClient() {
			const formData = this.createFormData()
			try {
				const createRes = await ClientsService.addClient(formData)
				setTimeout(() => {
					this.$router.push({
						name: 'EditClient',
						props: { newClient: false },
						params: { id: createRes.data.id }
					})
				}, 0)
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async updateClient() {
			try {
				const formData = this.createFormData()
				formData.append('id', this.$route.params.id)
				const updateRes = await ClientsService.updateClient(formData)
				if (updateRes.data) {
					this.fillForm(updateRes.data)
					setTimeout(() => {
						this.successHandler('Successfully Updated Client')
						this.form_change = false
						if (this.logo_small_image) {
							this.logo_small_image = ''
						}
						if (this.logo_large_image) {
							this.logo_large_image = ''
						}
					}, 0)
				}
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async deleteClient() {
			try {
				const dialogRes = await this.$refs.modalDialog.pop({
					title: 'Delete Client',
					message: `Are you sure?<br/>This cannot be undone.`
				})
				if (dialogRes) {
					await ClientsService.deleteClient(this.$route.params.id)
					this.$router.push({
						name: 'Clients'
					})
				}
			} catch (e) {
				this.errorHandler(e)
			}
		},
		async deployClient() {
			try {
				await ClientsService.deployClient(this.$route.params.id)
				this.successHandler(
					'Successfully Triggered Deployment Pipeline For Client: ' +
						this.clientForm.name
				)
			} catch (e) {
				this.errorHandler(e)
			}
		}
	}
}
</script>

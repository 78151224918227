<template>
    <v-card light tile class="mb-4">
        <v-card-title>Onboarding Settings</v-card-title>
        <v-card-text>
            <v-form class="ml-4" v-model="valid">
                <v-switch
                    v-model="onboardingSettings.completed"
                    label="Completed"
                    name="completed"
                ></v-switch>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
	name: 'OnboardingSettingsForm',
	data() {
		return {
			valid: true
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		onboardingSettings: {
			get() {
				return this.value
			},
			set(onboardingSettings) {
				this.$emit('input', onboardingSettings)
			}
		}
	}
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"justify-center fill-height"},[_c('v-col',{staticClass:"fill-height"},[_c('v-card',{attrs:{"light":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"secondary","elevation":1}},[_c('v-toolbar-title',[_vm._v("Users")])],1),_c('v-card-text',[_c('v-alert',{attrs:{"color":"error","dark":"","elevation":"2","type":"error","value":!!_vm.error_message.length,"transition":"fade-transition","dismissible":""}},[_vm._v(_vm._s(_vm.error_message))]),(_vm.users.length > 0)?[_c('v-simple-table',{attrs:{"dense":false,"fixed-header":true},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"30%"}},[_vm._v(" Email ")]),_c('th',{staticClass:"text-center",attrs:{"width":"40%"}},[_vm._v(" Action ")])])]),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('td',{staticClass:"text-left pl-6"},[_vm._v(" "+_vm._s(user.email)+" ")]),_c('td',{staticClass:"text-right"},[(user.currentUser)?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","tile":"","to":{
                                                        name: 'EditUser',
                                                        params: {
                                                            id: user.id
                                                        }
                                                    }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v("Edit ")],1)]:[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","tile":"","color":"error"},on:{"click":function($event){return _vm.resetPassword(user)}}},[(
                                                            !user.resettingPassword
                                                        )?[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock-reset")]),_vm._v("Reset Password ")]:[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"error"}})]],2)],_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","tile":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(user.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1)],2)])}),0)]},proxy:true}],null,false,849180196)})]:[_c('div',{staticClass:"mb-4"},[_vm._v(" There are no users.. Lets add one now ")]),_c('div',[_c('v-btn',{attrs:{"large":"","color":"primary","to":{ name: 'NewUser' }}},[_vm._v("Add User")])],1)]],2),(_vm.users.length)?_c('v-btn',{attrs:{"absolute":"","color":"primary","fab":"","bottom":"","right":"","to":{ name: 'NewUser' }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Api from '@/services/api'

export default {
	fetchUsers() {
		return Api().get('users')
	},
	addUser(params) {
		return Api().post('users', params)
	},
	updateUser(params) {
		return Api().put('users/' + params.id, params)
	},
	resetPassword(params) {
		return Api().put('users/resetPassword/' + params.id, params)
	},
	getUser(params) {
		return Api().get('users/' + params.id)
	},
	deleteUser(id) {
		return Api().delete('users/' + id)
	}

}

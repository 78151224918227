<template>
    <v-container fill-height fluid>
        <v-row class="justify-center fill-height">
            <v-col class="fill-height">
                <v-card>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>API Keys</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <template v-if="apiKeys.length > 0" class="table-wrap">
                            <v-simple-table :dense="false" :fixed-header="true">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" width="30%">
                                                Name
                                            </th>
                                            <th class="text-left" width="30%">
                                                Description
                                            </th>
                                            <th class="text-center" width="40%">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="apiKey in apiKeys"
                                            :key="apiKey.id"
                                        >
                                            <td class="text-left pl-6">
                                                {{ apiKey.name }}
                                            </td>
                                            <td class="text-left pl-6">
                                                {{ apiKey.description }}
                                            </td>
                                            <td class="text-right">
                                                <v-btn
                                                    :to="{
                                                        name: 'EditApiKey',
                                                        params: {
                                                            id: apiKey.id
                                                        }
                                                    }"
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                >
                                                    <v-icon left
                                                        >mdi-pencil</v-icon
                                                    >Edit
                                                </v-btn>
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                    @click="
                                                        deleteApiKey(apiKey.id)
                                                    "
                                                    color="error"
                                                >
                                                    <v-icon left
                                                        >mdi-delete</v-icon
                                                    >Delete
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                        <template v-else>
                            <div class="mb-4">There are no API Keys..</div>
                            <div>
                                <v-btn
                                    large
                                    color="info"
                                    :to="{ name: 'NewApiKey' }"
                                    >Add ApiKey</v-btn
                                >
                            </div>
                        </template>
                    </v-card-text>
                    <v-btn
                        v-if="apiKeys.length"
                        absolute
                        color="info"
                        fab
                        bottom
                        right
                        :to="{ name: 'NewApiKey' }"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ApiKeysService from '@/services/apikeys'
export default {
	data() {
		return {
			apiKeys: []
		}
	},
	beforeMount() {
		this.getApiKeys()
	},
	methods: {
		async getApiKeys() {
			const getApiKeysRes = await ApiKeysService.fetchApiKeys()
			this.apiKeys = getApiKeysRes.data
		},
		async deleteApiKey(id) {
			await ApiKeysService.deleteApiKey(id)
			this.apiKeys = this.apiKeys.filter(x => x.id !== id)
		}
	}
}
</script>
import moment from 'moment';

export default {
	state: {
		status: '',
		token: localStorage.getItem('jwtToken') || '',
		refreshToken: localStorage.getItem('refreshToken') || '',
		user: JSON.parse((localStorage.getItem('user') || '{}')),
		expires_at: localStorage.getItem('expires_at') || ''
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading'
		},
		auth_success(state, { token, user, refreshToken, expires_at }) {
			state.status = 'success'
			state.token = token
			state.user = user
			state.token = token;
			state.refreshToken = refreshToken;
			state.expires_at = expires_at;
		},
		auth_error(state) {
			state.status = 'error'
		},
		logout(state) {
			state.status = ''
			state.token = ''
			state.refreshToken = '';
			state.expires_at = Date.now();
			state.user = {}
		}

	},
	actions: {
		auth_success({ commit }, { token, user, refreshToken, expires_at }) {
			commit('auth_success', { token, user, refreshToken, expires_at })
		},
		logout({ commit }) {
			commit('logout')
		}

	},
	getters: {
		isLoggedIn: state => state.token !== '',
		authStatus: state => state.status,
		user: state => state.user,
		token: state => state.token,
		refreshToken: state => state.refreshToken,
		expires_at: state => state.expires_at,
		getExpiration: (state) => {
			return moment(state.expires_at)
		}
	}
}
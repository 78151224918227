<template>
    <v-container fill-height fluid>
        <v-row class="justify-center fill-height">
            <v-col class="fill-height">
                <v-card light>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Users</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-alert
                            color="error"
                            dark
                            elevation="2"
                            type="error"
                            :value="!!error_message.length"
                            transition="fade-transition"
                            dismissible
                            >{{ error_message }}</v-alert
                        >
                        <template v-if="users.length > 0" class="table-wrap">
                            <v-simple-table :dense="false" :fixed-header="true">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" width="30%">
                                                Email
                                            </th>
                                            <th class="text-center" width="40%">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="user in users"
                                            :key="user.id"
                                        >
                                            <td class="text-left pl-6">
                                                {{ user.email }}
                                            </td>
                                            <td class="text-right">
                                                <template
                                                    v-if="user.currentUser"
                                                >
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        tile
                                                        :to="{
                                                            name: 'EditUser',
                                                            params: {
                                                                id: user.id
                                                            }
                                                        }"
                                                    >
                                                        <v-icon left
                                                            >mdi-account</v-icon
                                                        >Edit
                                                    </v-btn>
                                                </template>
                                                <template v-else>
                                                    <v-btn
                                                        class="ma-2"
                                                        outlined
                                                        tile
                                                        @click="
                                                            resetPassword(user)
                                                        "
                                                        color="error"
                                                    >
                                                        <template
                                                            v-if="
                                                                !user.resettingPassword
                                                            "
                                                        >
                                                            <v-icon left
                                                                >mdi-lock-reset</v-icon
                                                            >Reset Password
                                                        </template>
                                                        <template v-else>
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="error"
                                                            ></v-progress-circular>
                                                        </template>
                                                    </v-btn>
                                                </template>
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    tile
                                                    @click="deleteUser(user.id)"
                                                    color="error"
                                                >
                                                    <v-icon left
                                                        >mdi-delete</v-icon
                                                    >Delete
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                        <template v-else>
                            <div class="mb-4">
                                There are no users.. Lets add one now
                            </div>
                            <div>
                                <v-btn
                                    large
                                    color="primary"
                                    :to="{ name: 'NewUser' }"
                                    >Add User</v-btn
                                >
                            </div>
                        </template>
                    </v-card-text>
                    <v-btn
                        v-if="users.length"
                        absolute
                        color="primary"
                        fab
                        bottom
                        right
                        :to="{ name: 'NewUser' }"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UsersService from '@/services/users'
export default {
	name: 'users',
	data() {
		return {
			users: [],
			error_message: ''
		}
	},
	computed: {
		user() {
			return this.$store.getters.user
		}
	},
	beforeMount() {
		this.getUsers()
	},
	methods: {
		async getUsers() {
			const getUsersRes = await UsersService.fetchUsers()
			this.users = getUsersRes.data.map(x => {
				x.resettingPassword = false
				x.currentUser = x.email === this.user.email
				return x
			})
		},
		async resetPassword(user) {
			user.resettingPassword = true
			try {
				await UsersService.resetPassword(user)
			} catch (e) {
				this.error_message =
					e.response && e.response.data && e.response.data.message
						? e.response.data.message
						: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
			user.resettingPassword = false
		},
		async deleteUser(id) {
			try {
				await UsersService.deleteUser(id)
				this.users = this.users.filter(x => x.id !== id)
			} catch (e) {
				console.error(e.response.data)
				this.error_message = e.response.data.message
					? e.response.data.message
					: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
		}
	}
}
</script>
<style type="text/css">
a.add_user_link {
    background: #4d7ef7;
    color: #fff;
    padding: 10px 80px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}
</style>

<template>
    <v-container fill-height fluid>
        <v-row class="justify-center align-center fill-height">
            <v-col class="fill-height">
                <v-card>
                    <v-toolbar dark color="secondary" :elevation="1">
                        <v-toolbar-title>Add API Key</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                            <v-alert
                                color="error"
                                dark
                                elevation="2"
                                type="error"
                                :value="!!error_message.length"
                                transition="fade-transition"
                                dismissible
                                >{{ error_message }}</v-alert
                            >
                            <v-text-field
                                v-model="name"
                                label="Name"
                                name="name"
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                v-if="!newKey"
                                v-model="key"
                                label="Key"
                                name="key"
                                disabled
                            ></v-text-field>
                            <v-textarea
                                placeholder="Description"
                                name="Description"
                                label="Description"
                                v-model="description"
                                rows="1"
                                auto-grow="true"
                            ></v-textarea>
                            <v-btn
                                v-if="newKey"
                                :disabled="!valid"
                                color="success"
                                @click="addApiKey"
                                >Add</v-btn
                            >
                            <v-btn
                                v-else
                                :disabled="!valid"
                                color="success"
                                @click="updateApiKey"
                                >Update</v-btn
                            >
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <modal-dialog ref="modalDialog"></modal-dialog>
    </v-container>
</template>
<script>
import ApiKeysService from '@/services/apikeys'
import Dialog from '@/components/simpleDialog'

export default {
	name: 'NewApiKey',
	props: ['newKey'],
	components: {
		'modal-dialog': Dialog
	},
	data() {
		return {
			name: '',
			description: '',
			error_message: '',
			valid: true,
			rules: {
				required: v => !!v || 'Required.'
			}
		}
	},
	beforeMount() {
		if (!this.newKey) {
			this.getApiKey()
		}
	},
	methods: {
		async getApiKey() {
			const response = await ApiKeysService.getApiKey({
				id: this.$route.params.id
			})
			this.name = response.data.name
			this.description = response.data.description
		},
		async addApiKey() {
			try {
				const keyRes = await ApiKeysService.addApiKey({
					name: this.name,
					description: this.description
				})
				if (keyRes.data && keyRes.data.unencrypted_key) {
					await this.$refs.modalDialog.pop({
						title: 'API Key',
						message: `This is your new API Key.<br/>
					Please save this for your references as this won't be shown again.<br/>
					<p>
					<span
					style="font-family: sans-serif; color:mediumspringgreen; font-size: 24px; font-weight: bold; margin: 0; Margin-bottom: 15px;"
					>${keyRes.data.unencrypted_key}</span>
					</p>
					`
					})
					this.$router.push({ name: 'ApiKeys' })
				}
			} catch (e) {
				console.error(e.response.data)
				this.error_message = e.response.data.message
					? e.response.data.message
					: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
		},
		async updateApiKey() {
			try {
				await ApiKeysService.updateApiKey({
					id: this.$route.params.id,
					name: this.name,
					description: this.description
				})
				this.$router.push({ name: 'ApiKeys' })
			} catch (e) {
				console.error(e.response.data)
				this.error_message = e.response.data.message
					? e.response.data.message
					: 'An Error Occurred'
				setTimeout(() => {
					this.error_message = ''
				}, 4000)
			}
		}
	}
}
</script>
import Api from '@/services/api'

export default {
	fetchClients() {
		return Api().get('clients')
	},
	addClient(params) {
		return Api().post('clients', params, {
			headers: {'Content-Type': 'multipart/form-data'},
			transformRequest: formData => formData
		})
	},
	updateClient(params) {
		return Api().put('clients/' + params.get('id'), params, {
			headers: {'Content-Type': 'multipart/form-data'},
			transformRequest: formData => formData
		})
	},
	getClient(params) {
		return Api().get('clients/' + params.id)
	},
	deleteClient(id) {
		return Api().delete('clients/' + id)
	},
	deployClient(id) {
		return Api().post('deployment/' + id)
	},
	migrateDB(from, to) {
		return Api().post('migrate/' + from + '/' + to)
	},
	deployAllClients() {
		return Api().post('deployment')
	}
}

<template>
    <v-container>
        <v-layout>
            <v-dialog v-model="active" max-width="40%">
                <v-card>
                    <v-toolbar dark color="secondary" class="mb-4 elevation-0 headline">{{title}}</v-toolbar>
                    <v-card-text class="subtitle-1" v-html="message"></v-card-text>
                    <v-card-text><v-select
                      v-model="from"
                      :items="itemsFrom"
                      density="compact"
                      label="From"
                    ></v-select>
                    <v-select
                      v-model="to"
                      :items="itemsTo"
                      density="compact"
                      label="To"
                    ></v-select></v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info darken-1" @click="deny">{{noText}}</v-btn>
                        <v-btn color="primary darken-1" @click="confirm">{{yesText}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
export default {
	data: () => {
		return {
			active: false,
			resolve: null,
			title: '',
			message: '',
			from: '',
			to: '',
			itemsFrom: [],
			itemsTo: [],
			noText: 'Cancel',
			yesText: 'Start'
		}
	},
	methods: {
		pop({ title = '', message = '', noText = 'Cancel', yesText = 'Start', itemsFrom = [], itemsTo = [] }) {
			this.title = title
			this.message = message
			this.noText = noText
			this.yesText = yesText
			this.active = true
			this.itemsFrom = itemsFrom
			this.itemsTo = itemsTo
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		confirm() {
			this.active = false
			this.resolve({from: this.from, to: this.to})
		},
		deny() {
			this.active = false
			this.resolve(false)
		}
	}
}
</script>

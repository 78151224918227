<template>
    <v-card light tile class="mb-4">
        <v-card-title>Portal Settings</v-card-title>
        <v-card-text>
            <v-form class="ml-4" v-model="valid">
                <!-- "active": false, -->
                <v-switch v-model="portalSettings.active" label="Active" name="active"></v-switch>
                <v-text-field
                    :disabled="true"
                    v-model="portalSettings.portal_threshold"
                    label="Portal Users Threshold"
                    name="portal_threshold"
                ></v-text-field>
								<v-text-field
                    :disabled="true"
                    v-model="portalSettings.portal_count"
                    label="Portal User Count"
                    name="portal_count"
                ></v-text-field>
                <!-- "defaultSalesRank": "Quarterly", -->
                <v-select
                    v-model="portalSettings.defaultSalesRank"
                    :items="salesRanks"
                    label="Default Sales Rank"
                ></v-select>
                <!-- "allowedComponentsList": [], -->
                <v-select
                    v-model="portalSettings.allowedComponentsList"
                    :items="componentsList"
                    multiple
                    label="Allowed Components"
                    :rules="[rules.require_one]"
                ></v-select>
                <!-- "homePage": "/", -->
                <v-select
                    v-model="portalSettings.homePage"
                    :items="routesList"
                    label="Home Page"
                    :rules="[rules.required]"
                ></v-select>
                <!-- "historicalDate": "", -->
                <v-menu v-model="show_date_picker" :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computedDate"
                            clearable
                            label="Historical Start Date"
                            readonly
                            v-on="on"
                            @click:clear="date = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @change="show_date_picker = false"></v-date-picker>
                </v-menu>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import { portalComponentsList, routesList } from './portal-components-list'
import moment from 'moment'

export default {
	name: 'PortalSettingsForm',

	data() {
		return {
			valid: true,
			rules: {
				required: v => !!v || 'Required.',
				require_one: v => v.length >= 1 || 'Must Enable At Least One.'
			},
			salesRanks: ['Monthly', 'Quarterly', 'Yearly'],
			componentsList: portalComponentsList,
			routesList,
			show_date_picker: false,
			date: ''
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		computedDate() {
			return this.portalSettings.historicalDate
				? moment(this.portalSettings.historicalDate).format('MMM DD YYYY')
				: ''
		},
		portalSettings: {
			get() {
				return this.value
			},
			set(portalSettings) {
				this.$emit('input', portalSettings)
			}
		}
	},
	watch: {
		date: function(date) {
			this.portalSettings.historicalDate = date
				? moment(date).format('YYYY-MM-DD')
				: ''
		}
	}
}
</script>
<template>
    <v-card light tile class="mb-4">
        <v-card-title>Client Settings</v-card-title>
        <v-card-text>
            <v-form class="ml-4" v-model="valid">
                <v-text-field
                    :disabled="true"
                    v-model="clientSettings.user_threshold"
                    label="User Threshold"
                    name="user_threshold"
                ></v-text-field>
								<v-text-field
                    :disabled="true"
                    v-model="clientSettings.user_count"
                    label="User Count"
                    name="user_count"
                ></v-text-field>
                <v-switch
                    v-model="clientSettings.allow_app_create"
                    label="Allow Ad Hoc App Creation"
                    name="allow_app_create"
                ></v-switch>
                <v-switch
                    v-model="clientSettings.twoFA_enabled"
                    label="Enable Two Factor Auth"
                    name="twoFA_enabled"
                ></v-switch>
                <v-select
                    v-model="clientSettings.allowedDashboardIDs"
                    :items="dashboardsList"
                    multiple
                    label="Enabled Dashboards"
                    :rules="[rules.require_one]"
                ></v-select>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import { dashboardsList } from './arca-dashboards-list'
export default {
	name: 'ClientSettingsForm',
	data() {
		return {
			valid: true,
			dashboardsList: dashboardsList.sort((a, b) => {
				return a.value - b.value
			}),
			rules: {
				require_one: v => v.length >= 1 || 'Must Enable At Least One.'
			}
		}
	},
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		clientSettings: {
			get() {
				return this.value
			},
			set(clientSettings) {
				this.$emit('input', clientSettings)
			}
		}
	}
}
</script>
import axios from 'axios'
import router from '../router'
import store from '../store'

const baseUrl = `/api`

export default () => {
  const myAxios = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const getAuthToken = async () => {
    // if the current store token expires soon
    if (store.getters.expires_at - 240 <= (Date.now() / 1000).toFixed(0)) {
      const refreshToken = store.getters.refreshToken
      const currentToken = store.getters.token
      const authTokenRequest = await axios.post(
        'auth/refresh',
        {},
        {
          baseURL: baseUrl,
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentToken,
            refreshtoken: refreshToken
          }
        }
      )
      const {
        user,
        token,
        refreshToken: newRefreshToken,
        expires_at
      } = authTokenRequest.data
      localStorage.setItem('jwtToken', token)
      localStorage.setItem('refreshToken', newRefreshToken)
      localStorage.setItem('expires_at', expires_at)
      localStorage.setItem('user', JSON.stringify(user))
      store.dispatch('auth_success', {
        token,
        user,
        refreshToken: newRefreshToken,
        expires_at
      })
      return token
    }
    return store.getters.token
  }
  myAxios.interceptors.request.use(async (config) => {
    if (!config.url.match('auth')) {
      config.headers['Authorization'] = await getAuthToken()
    }
    return config
  })
  myAxios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.request.status === 401 && error.config.__isRetryRequest) {
        store.dispatch('logout')
        return router.push('/login')
      } else if (error.response.status === 401) {
        error.config.__isRetryRequest = true
        return myAxios.request(error.config)
      }
      return Promise.reject(error)
    }
  )
  return myAxios
}

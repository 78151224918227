import Api from '@/services/api'
import store from '@/store/index.js'

export default {
	async login(credentials) {
		const response = await Api().post('/auth/login', credentials)
		const { user, token, refreshToken, expires_at } = response.data
		localStorage.setItem('jwtToken', token)
		localStorage.setItem('refreshToken', refreshToken)
		localStorage.setItem('expires_at', expires_at)
		localStorage.setItem('user', JSON.stringify(user))
		store.dispatch('auth_success', { token, user, refreshToken, expires_at })
		return response;
	},
	logout() {
		localStorage.removeItem('jwtToken')
		localStorage.removeItem('refreshToken')
		store.dispatch('logout')
	}
}
